var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "structure-panel" },
    [
      _c(
        "div",
        {
          ref: "structurepanel",
          staticClass: "structure-wrapper",
          class: { hovered: _vm.hovered || _vm.isFullscreen },
          on: {
            mouseover: function ($event) {
              _vm.hovered = true
            },
            mouseleave: function ($event) {
              _vm.hovered = false
            },
          },
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                "open-delay": "300",
                bottom: "",
                attach: ".structure-wrapper",
                "background-color": "transparent",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _vm.toolbar
                        ? _c(
                            "v-icon",
                            _vm._g(
                              {
                                staticClass: "help",
                                attrs: { light: _vm.isFullscreen },
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$MDI.HelpCircleOutline))]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("span", [
                _c("dl", { staticStyle: { "text-align": "center" } }, [
                  _c("dt", [
                    _c(
                      "svg",
                      {
                        staticStyle: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "2",
                        },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          "xml:space": "preserve",
                          viewBox: "0 0 32 32",
                        },
                      },
                      [
                        _c("title", [_vm._v("Left click")]),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          staticStyle: { fill: "red" },
                          attrs: {
                            id: "left",
                            d: "M15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            id: "middle-inactive",
                            d: "M14.6 4h2.8v8h-2.8z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "\n                            Rotate\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dt", [
                    _c(
                      "svg",
                      {
                        staticStyle: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "2",
                        },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          "xml:space": "preserve",
                          viewBox: "0 0 32 32",
                        },
                      },
                      [
                        _c("title", [_vm._v("Right click")]),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          staticStyle: { fill: "red" },
                          attrs: {
                            id: "right",
                            d: "M16.5 2h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            id: "middle-inactive",
                            d: "M14.6 4h2.8v8h-2.8z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "\n                            Pan\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dt", [
                    _c(
                      "svg",
                      {
                        staticStyle: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "2",
                        },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          "xml:space": "preserve",
                          viewBox: "0 0 32 32",
                        },
                      },
                      [
                        _c("title", [_vm._v("Scroll wheel")]),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          staticStyle: { fill: "red" },
                          attrs: {
                            id: "middle-active",
                            d: "M14.6 4h2.8v8h-2.8z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "\n                            Zoom\n                        "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.toolbar
            ? _c(
                "div",
                { staticClass: "toolbar-panel" },
                [
                  _c(
                    "v-item-group",
                    {
                      staticClass: "v-btn-toggle",
                      attrs: { light: _vm.isFullscreen },
                    },
                    [
                      _vm.secondComponent
                        ? _c(
                            "v-btn",
                            _vm._b(
                              {
                                attrs: { title: "Save PDB" },
                                on: {
                                  click: function ($event) {
                                    return _vm.makePdb()
                                  },
                                },
                              },
                              "v-btn",
                              _vm.tbButtonBindings,
                              false
                            ),
                            [
                              _c(
                                "v-icon",
                                _vm._b({}, "v-icon", _vm.tbIconBindings, false),
                                [
                                  _vm._v(
                                    "M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h14Zm0 8v-.8c0-.7-.6-1.2-1.3-1.2h-2.4v6h2.4c.7 0 1.2-.5 1.2-1.2v-1c0-.4-.4-.8-.9-.8.5 0 1-.4 1-1Zm-9.7.5v-1c0-.8-.7-1.5-1.5-1.5H5.3v6h1.5v-2h1c.8 0 1.5-.7 1.5-1.5Zm5 2v-3c0-.8-.7-1.5-1.5-1.5h-2.5v6h2.5c.8 0 1.5-.7 1.5-1.5Zm3.4.3h-1.2v-1.2h1.2v1.2Zm-5.9-3.3v3h1v-3h-1Zm-5 0v1h1v-1h-1Zm11 .9h-1.3v-1.2h1.2v1.2Z"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isFullscreen
                                ? _c("span", [_vm._v(" Save PDB")])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { title: "Save image" },
                            on: {
                              click: function ($event) {
                                return _vm.makeImage()
                              },
                            },
                          },
                          "v-btn",
                          _vm.tbButtonBindings,
                          false
                        ),
                        [
                          _c(
                            "v-icon",
                            _vm._b({}, "v-icon", _vm.tbIconBindings, false),
                            [
                              _vm._v(
                                "M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9 11.5C9 12.3 8.3 13 7.5 13H6.5V15H5V9H7.5C8.3 9 9 9.7 9 10.5V11.5M14 15H12.5L11.5 12.5V15H10V9H11.5L12.5 11.5V9H14V15M19 10.5H16.5V13.5H17.5V12H19V13.7C19 14.4 18.5 15 17.7 15H16.4C15.6 15 15.1 14.3 15.1 13.7V10.4C15 9.7 15.5 9 16.3 9H17.6C18.4 9 18.9 9.7 18.9 10.3V10.5H19M6.5 10.5H7.5V11.5H6.5V10.5Z"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isFullscreen
                            ? _c("span", [_vm._v(" Save image")])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: {
                              title:
                                "Reset the view to the original position and zoom level",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetView()
                              },
                            },
                          },
                          "v-btn",
                          _vm.tbButtonBindings,
                          false
                        ),
                        [
                          _c(
                            "v-icon",
                            _vm._b({}, "v-icon", _vm.tbIconBindings, false),
                            [_vm._v(_vm._s(_vm.$MDI.Restore))]
                          ),
                          _vm._v(" "),
                          _vm.isFullscreen
                            ? _c("span", [_vm._v(" Reset view")])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: {
                              title:
                                "Enter fullscreen mode - press ESC to exit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleFullscreen()
                              },
                            },
                          },
                          "v-btn",
                          _vm.tbButtonBindings,
                          false
                        ),
                        [
                          _c(
                            "v-icon",
                            _vm._b({}, "v-icon", _vm.tbIconBindings, false),
                            [_vm._v(_vm._s(_vm.$MDI.Fullscreen))]
                          ),
                          _vm._v(" "),
                          _vm.isFullscreen
                            ? _c("span", [_vm._v(" Fullscreen")])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { ref: "viewport", staticClass: "structure-viewer" }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.second
        ? [
            _vm.secondComponent == null
              ? _c("span", [_vm._v("Superposition loading")])
              : [
                  _c("span", { staticStyle: { color: "#FFC107" } }, [
                    _vm._v(_vm._s(_vm.second)),
                  ]),
                  _vm._v(" superposed on representative "),
                  _c("span", { staticStyle: { color: "#1E88E5" } }, [
                    _vm._v(_vm._s(_vm.cluster)),
                  ]),
                  _vm._v(" "),
                  _vm.tmOutput
                    ? [
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [
                          _c("strong", [_vm._v("TM-score:")]),
                          _vm._v(
                            "  " + _vm._s(_vm.tmOutput.tmScore.toFixed(2))
                          ),
                        ]),
                        _vm._v(" \n                    "),
                        _c("span", [
                          _c("strong", [_vm._v("RMSD:")]),
                          _vm._v(
                            "  " + _vm._s(_vm.tmOutput.rmsd.toFixed(2)) + " Å"
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }