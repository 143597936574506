var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticStyle: { margin: "1em" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md8: "" } },
        [
          _c(
            "panel",
            [
              _vm.response
                ? _c("template", { slot: "header" }, [
                    _vm._v(
                      "\n            Entry: " +
                        _vm._s(
                          _vm.response
                            ? _vm.response.rep_accession
                            : "Loading..."
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "template",
                { slot: "toolbar-extra" },
                [
                  _vm.response && _vm.response.warning == true
                    ? _c("v-chip", { attrs: { color: "error" } }, [
                        _vm._v("Warning"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "", left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var menu = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { plain: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  menu
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(_vm.$MDI.NotificationClearAll)
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                        MSA\n                    "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                href:
                                  "https://bfvd.steineggerlab.workers.dev/a3m/" +
                                  _vm.response.rep_accession +
                                  ".a3m",
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(_vm.$MDI.FileDownloadOutline)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "\n                                MSA (.a3m)\n                            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-dialog",
                            {
                              attrs: { fullscreen: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-list-item",
                                        _vm._g(
                                          _vm._b(
                                            {},
                                            "v-list-item",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$MDI.ChartBarStacked
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "\n                                        Conservation\n                                    "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.dialog,
                                callback: function ($$v) {
                                  _vm.dialog = $$v
                                },
                                expression: "dialog",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "Panel",
                                [
                                  _c("template", { slot: "header" }, [
                                    _vm._v(
                                      "\n                                MSA conservation\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "template",
                                    { slot: "toolbar-extra" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialog = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Close\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "template",
                                    { slot: "content" },
                                    [
                                      _c("MsaLogoPlot", {
                                        attrs: {
                                          accession: _vm.$route.params.cluster,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.response
                ? _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("h3", [_vm._v("Representative summary")]),
                      _vm._v(" "),
                      _c("dl", { staticClass: "dl-4" }, [
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Accession\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ExternalLinks", {
                                attrs: {
                                  accession: _vm.response.rep_accession,
                                },
                              }),
                              _c("br"),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.response.description) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Length\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.rep_len) +
                                " aa\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    pLDDT\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.rep_plddt.toFixed(2)) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Singleton cluster\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.is_dark ? "yes" : "no") +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "grid-area": "2 / 1 / 3 / 5" } },
                          [
                            _c("dt", [
                              _vm._v(
                                "\n                    Taxonomy\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "dd",
                              [
                                _vm._l(
                                  _vm.response.rep_lineage,
                                  function (taxonomy, index) {
                                    return [
                                      _c("TaxSpan", {
                                        key: taxonomy.id,
                                        attrs: { taxonomy: taxonomy },
                                      }),
                                      index <
                                      _vm.response.rep_lineage.length - 1
                                        ? [_vm._v(" » ")]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.response.hosts.length > 0
                              ? _c("dt", [
                                  _vm._v(
                                    "\n                    Host\n                "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.response.hosts.length > 0
                              ? _c(
                                  "dd",
                                  [
                                    _vm._l(
                                      _vm.response.hosts,
                                      function (taxonomy, index) {
                                        return [
                                          _c("TaxSpan", {
                                            key: taxonomy.id,
                                            attrs: { taxonomy: taxonomy },
                                          }),
                                          index < _vm.response.hosts.length - 1
                                            ? [_vm._v(" , ")]
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticStyle: { "margin-top": "0.5em" },
                      }),
                      _vm._v(" "),
                      _c("h3", { staticStyle: { "margin-top": "1em" } }, [
                        _vm._v(
                          "\n                    UniRef cluster summary\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("dl", { staticClass: "dl-4" }, [
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Number of members\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.n_mem) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Average length\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.avg_len.toFixed(2)) +
                                " aa\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "grid-area": "2 / 1 / 3 / 5" } },
                          [
                            _c("dt", [
                              _vm._v(
                                "\n                    Lowest common ancestor and lineage\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "dd",
                              [
                                _vm._l(
                                  _vm.response.lineage,
                                  function (taxonomy, index) {
                                    return [
                                      _c("TaxSpan", {
                                        key: taxonomy.id,
                                        attrs: { taxonomy: taxonomy },
                                      }),
                                      index < _vm.response.lineage.length - 1
                                        ? [_vm._v(" » ")]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.response && _vm.response.warning == true
                        ? [
                            _c("v-divider", {
                              staticStyle: { "margin-top": "0.5em" },
                            }),
                            _vm._v(" "),
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  "margin-top": "1em",
                                  color: "#F44336",
                                  "text-decoration": "underline",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Warning!\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                    This cluster was wrongly merged with another cluster. We are working on a fix.\n                "
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "", md4: "" } },
        [
          _c(
            "Panel",
            { staticClass: "repr-structure" },
            [
              _c("template", { slot: "header" }, [
                _vm._v("\n            Representative structure\n        "),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "toolbar-extra" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        plain: "",
                        href:
                          "https://bfvd.steineggerlab.workers.dev/pdb/" +
                          _vm.response.rep_accession +
                          ".pdb",
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.$MDI.FileDownloadOutline)),
                      ]),
                      _vm._v("\n                PDB\n            "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.response
                ? _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.$route.params.cluster
                        ? _c("StructureViewer", {
                            attrs: {
                              cluster: _vm.$route.params.cluster,
                              second: _vm.second,
                              bgColorDark: "#2e2e2e",
                            },
                            on: {
                              reset: function ($event) {
                                _vm.second = ""
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v("\np    "),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("Members", {
            attrs: { cluster: _vm.$route.params.cluster },
            on: {
              select: function (accession) {
                return (_vm.second = accession)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("Similars", {
            attrs: { cluster: _vm.$route.params.cluster },
            on: {
              select: function (accession) {
                return (_vm.second = accession)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticClass: "mt-3", attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            { attrs: { rounded: "0" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pb-0 mb-0",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _c("div", { staticClass: "text-h5 mb-0" }, [
                        _vm._v("Reference"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pt-0 mt-0",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "text-subtitle-1 mb-0",
                          staticStyle: { "word-break": "break-word" },
                        },
                        [
                          _vm._v(
                            "\n                    Kim R, Levy Karin E, Mirdita M, Steinegger M.\n                    "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkae1119/7906834",
                                target: "_blank",
                                rel: "noopener",
                              },
                            },
                            [
                              _vm._v(
                                "BFVD - a large repository of predicted viral protein structures."
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                    Nucleic Acids Research, gkae1119, 2024.\n                "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }