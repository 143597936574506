var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [_vm._v("Loading...")]
    ),
    _vm._v(" "),
    _c("div", { ref: "target" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }