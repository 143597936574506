import { select, scaleLinear, axisBottom, axisLeft, max, sum } from "d3";

let inconsolata = {};
inconsolata.build = function(svg) {
	var defs = svg.append("defs");
	defs.append("path").attr("class", "glyph").attr("id", "glyph-A").attr("d", "m 0,-3.6679688e-5 19.27,-62.000000320312 0.7,0 21.03,62.000000320312 -6.3,0 -6.05,-17.800000320312 -17.35,0 -5.26,17.800000320312 -6.04,0 z m 27.25,-23.080000320312 -7.7,-23.27 -7.02,23.27 14.72,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-B").attr("d", "m 0,-61.990037 17.7444,0 q 5.4213,0 9.3822,1.05 3.961,1.04 6.3616,3.13 2.4006,2.1 3.7009,4.83 1.3003,2.74 1.3003,6.03 0,4.38 -2.4006,8.26 -2.4006,3.87 -6.8016,5.46 2.6006,0.9 4.7511,2.54 2.1606,1.64 3.761,3.73 1.6003,2.1 2.4005,4.53 0.8002,2.44 0.8002,5.03 0,7.8500003 -5.6013,12.6200003 -5.6314,4.780000020312 -17.7644,4.780000020312 l -17.6343,0 0,-62.000000320312 z m 6.9217,5.87 0,19.8 9.8224,0 q 4.201,0 6.9217,-0.74 2.7007,-0.75 4.3611,-2.04 1.6504,-1.3 2.4506,-3.14 0.8002,-1.85 1.0002,-3.94 -0.2,-2.1 -0.9502,-3.88 -0.7502,-1.8 -2.3506,-3.13 -1.6004,-1.34 -4.101,-2.13 -2.5206,-0.8 -6.2315,-0.8 l -10.9227,0 z m 0,25.68 0,24.3800003 11.9329,0 q 8.022,0 11.1728,-3.33 3.1607,-3.3400003 3.5608,-8.4000003 -0.2,-2.8 -1.1002,-5.1 -0.9003,-2.28 -2.7607,-4.02 -1.8505,-1.74 -5.0012,-2.64 -3.1708,-0.9 -7.5819,-0.9 l -10.2225,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-C").attr("d", "m 34.2083,-47.805437 q 0,-1.1804 -0.3,-1.7606 -1.9005,-3.201 -4.9712,-5.1016 -3.0708,-1.9007 -6.5016,-1.9007 -3.2708,0 -6.1415,1.7006 -2.9007,1.7005 -5.1013,4.9616 -2.2005,3.2611 -3.4408,7.9226 -1.2503,4.6615 -1.2503,10.6034 0,5.7318 1.2603,10.4434 1.2403,4.7015 3.5009,8.0626 2.2505,3.3610003 5.2612,5.2016003 3.0208,1.8506 6.4716,1.8506 3.5409,0 6.8417,-2.0006 3.3008,-1.9807 5.3213,-5.4018003 l 4.9812,3.3211003 q -1.4404,2.4208 -3.4508,4.2614 -2.0205,1.8506 -4.2711,3.101 -2.2505,1.2804 -4.5011,1.90060002 -2.2605,0.640200000312 -4.5611,0.640200000312 -5.1713,0 -9.1422,-1.800600020312 -3.981,-1.8006 -7.2818,-5.5818 -3.3008,-3.8012003 -5.1313,-9.6331003 -1.8004,-5.8319 -1.8004,-13.8845 0,-8.9429 2.1005,-14.9248 2.1005,-5.9819 5.5614,-9.5731 3.4508,-3.6011 7.3318,-5.1016 3.8709,-1.5005 7.5018,-1.5005 2.9807,0 5.8014,0.8803 2.8307,0.8702 5.2813,2.4207 2.4406,1.5606 4.3511,3.7513 1.9204,2.1807 3.0707,4.9016 l -6.4216,3.201 q -0.3801,-0.2801 -0.3801,-0.9603 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-D").attr("d", "m 0,-62.000037 14.64,0 q 8.08,0 12.58,2.2 4.5,2.17 7.62,6.25 3.13,4.07 4.65,9.83 1.51,5.77 1.51,13.02 0,7.15 -1.82,12.82 -1.82,5.66 -5,9.6800003 -3.18,4.03 -8.23,6.1 -5.05,2.100000020312 -12.52,2.100000020312 l -13.43,0 0,-62.000000320312 z m 6.67,5.86 0,50.6800003 6.46,0 q 10.8,0 15.8,-6.4000003 5,-6.42 5,-18.44 -0.1,-12.13 -4.6,-18.98 -4.5,-6.86 -15.1,-6.86 l -7.55,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-E").attr("d", "m 0,-61.990037 41,0 0,6.16 -33.92,0 0,20.37 28.02,0 0,6.36 -28.02,0 0,22.9400003 33.6,0 0,6.160000020312 -40.68,0 0,-62.000000320312 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-F").attr("d", "m 0,-62.000037 41,0 0,6.06 -33,0 0,19.48 26.6,0 0,6.06 -26.6,0 0,30.400000320312 -8,0 0,-62.000000320312 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-G").attr("d", "m 40.8,-51.888437 -4.73,4.8408 q -0.58,-0.4 -1.26,-1.8403 -1.44,-3.2005 -4.5,-5.2008 -3.03,-1.9804 -7.1,-1.9804 -2.8,0 -5.75,1.1602 -2.95,1.1702 -5.36,3.9807 -2.43,2.8204 -3.93,7.4212 -1.5,4.6007 -1.5,11.6019 0,6.301 1.26,11.1618 1.26,4.8507 3.48,8.1013 2.23,3.2505003 5.28,4.9508003 3.05,1.7002 6.62,1.7002 6.3,0 11.4,-3.7006 l 0,-14.3623003 -10.91,0 0,-5.9209 17.2,0 0,23.6738003 q -8.6,6.301000020312 -17.7,6.301000020312 -5.4,0 -9.7,-2.180300020312 -4.32,-2.2004 -7.36,-6.161 -3.05,-3.9807003 -4.64,-9.6016003 -1.6,-5.6409 -1.6,-12.522 0,-9.5016 2.37,-15.9026 2.37,-6.401 6.14,-9.7616 3.78,-3.3505 7.7,-4.6007 3.9,-1.2702 7.1,-1.2702 5.52,0 10.25,2.7704 4.74,2.7605 7.26,7.3212 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-H").attr("d", "m 0,-61.990037 8.12,0 q 0.1,0.1 0.1,0.3 0,0.3 -0.26,0.8 -0.26,0.5 -0.36,1.3 l 0,24.13 25.4,0 0,-26.53 7.9,0 q 0.1,0.1 0.1,0.3 0,0.4 -0.3,0.84 -0.32,0.45 -0.43,1.05 l 0,59.800000320312 -7.4,0 0,-29.600000320312 -25.27,0 0,29.50000032 -7.6,0 0,-61.90000032 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-I").attr("d", "m 0.9,-62.000037 39.34,0 0,5.86 -15.67,0 0,50.3800003 16.43,0 0,5.760000020312 -41,0 0,-5.860000020312 15.66,0 0,-50.2800003 -14.76,0 0,-5.86 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-J").attr("d", "m 12.36,-62.000037 28.64,0 0,5.77 -9.65,0 0,34.72 q 0,5.95 -1.15,9.96 -1.16,4.0000003 -3.67,6.6500003 -2.5,2.65 -5.43,3.78 -2.9,1.120000020312 -6.33,1.120000020312 -8.94,0 -14.77,-6.750000020312 l 4.72,-5.5700003 q 0.3,0.3 0.35,0.93 0.06,0.64 0.36,1.03 l 1.2,1.1800003 q 0.4,0.5 1.3,1.04 0.9,0.53 2.07,0.97 1.15,0.45 2.36,0.8 1.2,0.34 2.4,0.34 5.04,0 7.35,-3.43 2.32,-3.4000003 2.32,-11.9200003 l 0,-34.8 -12.07,0 0,-5.78 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-K").attr("d", "m 0,-61.600037 8.03,0 q 0,0.58 -0.43,1.22 -0.43,0.64 -0.43,1.32 l 0,25.7 24.85,-28.64 q 2.1,0.5 4.97,0.5 l 2.47,0 -22.55,26.38 24.09,34.72000032 -8.6,0.400000000312 -20.55,-31.000000320312 -4.68,5.3 0,25.30000032 -7.17,0 0,-61.20000032 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-L").attr("d", "m 0,-62.000037 9,0 q 0,0.7 -0.48,1.6 -0.5,0.9 -0.5,1.78 l 0,52.5500003 32.98,0 0,6.070000020312 -41,0 0,-62.000000320312 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-M").attr("d", "m 0,-61.900037 5.38,0 15.07,30.3 15.36,-30.4 5.19,0 0,62.000000320312 -6.46,0 0,-46.700000320312 -13.2,24.94 -2.65,0 -12.34,-24.64 0,46.400000320312 -6.35,0 0,-61.900000320312 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-N").attr("d", "m 0,-3.6679688e-5 0,-61.900000320312 6.9,0 26.6,45.9 0,-46 7.5,0 q 0,0.6 -0.36,1.3 -0.35,0.7 -0.35,1.38 l 0,59.320000320312 -5.6,0 -27.89,-48.700000320312 0,48.700000320312 -6.8,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-O").attr("d", "m 20.87,-3.6679688e-5 q -4.73,0 -8.58,-2.280000020312 -3.86,-2.27 -6.6,-6.4 -2.73,-4.1000003 -4.22,-9.8700003 -1.47,-5.75 -1.47,-12.75 0,-6.96 1.53,-12.68 1.53,-5.72 4.3,-9.7 2.8,-3.96 6.7,-6.14 3.9,-2.18 8.53,-2.18 4.08,0 7.6,1.84 3.53,1.84 6.45,5.57 2.93,3.74 4.42,9.55 1.47,5.81 1.47,13.84 0,8.73 -1.76,14.68 -1.76,5.96 -4.64,9.6400003 -2.88,3.68 -6.45,5.28 -3.57,1.600000020312 -7.28,1.600000020312 z m -9.65,-12.100000320312 q 4.36,5.4200003 9.65,5.4200003 2.5,0 4.92,-1.12 2.4,-1.1 4.4,-3.9200003 2,-2.8 3.15,-7.56 1.16,-4.74 1.16,-12.1 0,-7.18 -1.3,-11.78 -1.3,-4.6 -3.28,-7.3 -2,-2.72 -4.18,-3.93 -2.18,-1.2 -4.68,-1.2 -2.88,-0.1 -5.3,1.06 -2.4,1.16 -4.54,3.78 -2.13,2.6 -3.47,7.17 -1.35,4.56 -1.35,11.73 0,6.68 1.35,11.82 1.34,5.13 3.47,7.94 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-P").attr("d", "m 0,-61.990037 20.655,0 q 5.3813,0 9.1422,1.34 3.7609,1.35 6.2015,3.8 2.4206,2.42 3.6009,5.6 1.2003,3.2 1.4004,6.78 -0.2001,3.58 -1.4004,6.7 -1.1803,3.15 -3.5008,5.44 -2.3306,2.3 -6.0015,3.63 -3.6609,1.33 -8.9322,1.33 l -13.7233,0 0,27.370000320312 -7.4418,0 0,-62.000000320312 z m 7.4418,6.57 0,21.9 12.9031,0 q 7.0317,0 9.9725,-2.94 2.9507,-2.94 3.2607,-7.8 -0.2,-2.3 -0.9802,-4.34 -0.7702,-2.05 -2.3206,-3.55 -1.5503,-1.5 -3.9809,-2.4 -2.4206,-0.88 -5.8315,-0.88 l -13.0031,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-Q").attr("d", "m 23.52,-13.630037 5.4,-4.6 11.5,13.4700003 -5.38,4.60000002 z m -2.65,13.630000320312 q -4.73,0 -8.58,-2.280000020312 -3.86,-2.27 -6.6,-6.4 -2.73,-4.1000003 -4.22,-9.8700003 -1.47,-5.75 -1.47,-12.75 0,-6.96 1.53,-12.68 1.53,-5.72 4.3,-9.7 2.8,-3.96 6.7,-6.14 3.9,-2.18 8.53,-2.18 4.08,0 7.6,1.84 3.53,1.84 6.45,5.57 2.93,3.74 4.42,9.55 1.47,5.81 1.47,13.84 0,8.73 -1.76,14.68 -1.76,5.96 -4.64,9.6400003 -2.88,3.68 -6.45,5.28 -3.57,1.600000020312 -7.28,1.600000020312 z m -9.65,-12.100000320312 q 4.36,5.4200003 9.65,5.4200003 2.5,0 4.92,-1.12 2.4,-1.1 4.4,-3.9200003 2,-2.8 3.15,-7.56 1.16,-4.74 1.16,-12.1 0,-7.18 -1.3,-11.78 -1.3,-4.6 -3.28,-7.3 -2,-2.72 -4.18,-3.93 -2.18,-1.2 -4.68,-1.2 -2.88,-0.1 -5.3,1.06 -2.4,1.16 -4.54,3.78 -2.13,2.6 -3.47,7.17 -1.35,4.56 -1.35,11.73 0,6.68 1.35,11.82 1.34,5.13 3.47,7.94 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-R").attr("d", "m 0,-62.000037 18.5,0 q 11.04,0 15.92,4.88 4.87,4.87 4.87,12.84 0,2.68 -0.9,5.32 -0.92,2.64 -2.58,4.93 -1.65,2.28 -4,3.98 -2.38,1.7 -5.3,2.38 l 14.49,27.670000320312 -7.84,0 -13.86,-27.370000320312 -12.18,0 0,27.370000320312 -7.12,0 0,-62.000000320312 z m 7.13,6.57 0,21.9 12.17,0 q 6.73,0 9.74,-2.94 3.02,-2.94 3.02,-7.8 0,-2.4 -0.8,-4.4 -0.8,-1.98 -2.27,-3.48 -1.47,-1.5 -3.88,-2.4 -2.4,-0.88 -5.83,-0.88 l -12.16,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-S").attr("d", "m 38.99,-54.426337 -4.48,5.8328 q -0.52,-0.2001 -0.57,-0.8204 -0.05,-0.6303 -0.26,-0.9205 -1.93,-2.7213 -5.23,-4.3821 -3.3,-1.6508 -8.2,-1.6508 -4.67,0 -7.78,2.6213 -3.1,2.6313 -3.1,6.233 0,2.1411 0.76,3.7018 0.77,1.5608 2.34,3.0215 1.58,1.4607 4.63,2.9714 3.04,1.5007 7.93,3.3516 4.68,1.8409 7.73,3.6518 3.05,1.8009 4.88,3.8419 1.83,2.0409 2.6,4.332 0.76,2.3012 0.76,5.1025 0,3.1315 -1.22,6.3031 -1.22,3.1515003 -3.82,5.6327003 -2.6,2.5012 -6.56,4.0419 -3.97,1.560800020312 -9.56,1.560800020312 -12.3,0 -19.84,-7.803800020312 l 4.17,-7.0034003 q 0.5,0.3002 0.5,0.9805 0,0.6803 0.32,1.0705 2.33,2.8214003 6.3,4.7223003 3.96,1.9009 9.56,1.9009 2.84,0 5.24,-0.9304 2.4,-0.9205 4.12,-2.4312 1.73,-1.5007003 2.74,-3.6017003 1.02,-2.081 1.02,-4.4222 0,-1.8509 -0.66,-3.3616 -0.66,-1.5007 -2.2,-2.8214 -1.5,-1.3006 -4.3,-2.6312 -2.8,-1.3007 -7.4,-3.0615 -4.97,-1.8409 -8.28,-3.7418 -3.3,-1.901 -5.34,-3.8519 -2.04,-1.9309 -2.9,-4.172 -0.87,-2.2411 -0.87,-4.8724 0,-3.3016 1.48,-6.233 1.5,-2.9214 4.08,-5.1025 2.6,-2.201 6.15,-3.4016 3.57,-1.2306 7.63,-1.2306 5.2,0 9.77,2.001 4.58,2.0009 7.84,5.6027 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-T").attr("d", "m 0,-62.000037 41,0 0,6.26 -17.46,0 0,55.740000320312 -6.74,0 0,-55.740000320312 -16.8,0 0,-6.26 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-U").attr("d", "m 0,-62.000037 8.02,0 q 0.1,0 0.1,0.2 0,0.4 -0.46,1.12 -0.45,0.73 -0.66,1.8 l 0,36.88 q 0,5.08 1.02,7.87 1,2.8 2.84,4.6000003 1.83,1.8 4.3,2.7 2.5,0.87 5.34,1.06 2.84,-0.2 5.33,-0.98 2.48,-0.78 4.36,-2.7 1.87,-1.9000003 2.93,-4.7300003 1.07,-2.83 1.07,-8.12 l 0,-39.7 6.81,0 0,39.6 q 0,5.97 -1.37,10.08 -1.37,4.1000003 -4.2,6.8400003 -2.85,2.74 -6.6,4.1 -3.76,1.380000020312 -8.43,1.380000020312 -4.57,0 -8.32,-1.370000020312 -3.76,-1.37 -6.45,-4 -2.7,-2.65 -4.16,-6.4600003 -1.47,-3.82 -1.47,-10.27 l 0,-39.9 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-V").attr("d", "m 0,-62.000037 6.6,0 14.5,47.3 13.65,-47.2 6.25,0 -18.92,61.900000320312 -2.9,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-W").attr("d", "m 0,-62.000037 5.66,0 5.83,41.63 8.7,-37.08 1.9,0 8.8,37.28 4.96,-41.83 5.15,0 -8.62,62.000000320312 -2.35,0 -9.3,-40.640000320312 -9.5,40.640000320312 -2.53,0 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-X").attr("d", "m 32.45,-62.000037 6.73,0 -15.18,30.3 17,31.700000320312 -7.6,0 -13.24,-24.540000320312 -12.86,24.540000320312 -7.3,0 16.32,-31.600000320312 -16.02,-30.4 7.2,0 12.57,23.55 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-Y").attr("d", "m 0,-62.000037 7.37,0 14.1,30.06 12.7,-30.06 6.83,0 -16.3,37.52 0,24.480000320312 -7,0 0,-24.480000320312 z")
	defs.append("path").attr("class", "glyph").attr("id", "glyph-Z").attr("d", "m 1.1997,-62.000037 38.1907,0 0,4.88 -30.6425,50.9500003 29.1729,0 q 0.6998,0 1.5396,-0.3 0.8498,-0.3 1.5396,-0.3 l 0,6.770000020312 -41,0 0,-4.880000020312 31.1624,-50.8500003 -29.9627,0 0,-6.27 z")
}

let logoplot = {};
logoplot.computeCounts = function(sequences) {
	let counts = [];
	const numSequences = sequences.length;
	const en = Math.log(20) - ((1 / Math.log(2)) * (20 - 1) / (2 * numSequences));
	for (var i = 0; i < sequences[0].length; ++i) {
		let freq = [];
		for (var j in sequences) {
			var char = sequences[j][i];
			if (char == '-') {
				continue;
			}

			if (typeof freq[char] !== 'undefined') {
				freq[char]++;
			} else {
				freq[char] = 1;
			}
		}

		let hi = 0.0;
		for (let j in freq) {
			freq[j] = freq[j] / numSequences;
			hi -= freq[j] * Math.log(freq[j]);
		}

		const ri = Math.abs(en - hi);
		let height = [];
		for (let j in freq) {
			height.push([j, freq[j] * ri]);
		}

		height.sort(function (a, b) {
			return (a[1] > b[1] ? 1 : -1);
		});
		counts.push(height);
	}
	return counts;
}

const maxCount = (data) => {
	return max(data, function(d) { return sum(d, function(d) { return d[1]; }); });
}

logoplot.build = function (selector, data, options) {
	options = options || {};
	let w = options.width || select(selector).style('width') || select(selector).attr('width');
	let h = options.height || select(selector).style('height') || select(selector).attr('height');

	let max = options.max || maxCount(data);

	const start = options.start || 0;
	const end = options.end || data.length;

	const margin = options.margin || {top: 20, right: 20, bottom: 25, left: 25};

	w = parseInt(w, 10) - margin.left - margin.right;
	h = parseInt(h, 10) - margin.top - margin.bottom;

	const svg = select(selector)
		.append("svg")
			.attr('class', 'logoplot')
			.attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", `0 0 ${w + margin.left + margin.right} ${h + margin.top + margin.bottom}`)
		.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

	const x = scaleLinear()
		.range([0, w])
		.domain([start, end]);

	const xAxis = axisBottom(x)
		.ticks(20);

	svg.append("g")
		.attr("class", "x axis")
		.attr("transform", "translate(0," + h + ")")
		.call(xAxis);

	const y = scaleLinear()
		.range([h, 0])
		.domain([0, max]);

	const yAxis = axisLeft(y)
		.ticks(5);

	svg.append("g")
		.attr("class", "y axis")
		.call(yAxis);

	let group = svg
		.append('g')
			.attr("class", "data")

	inconsolata.build(group);
	const fontScale = 1/62;
	for(let i = 0; i < data.length; i++) {
		let ypos = y(0);
		for(let j = 0; j < data[i].length; j++) {
			const relHeight = data[i][j][1]/max*y(0);
			group.append('use')
				.attr("xlink:href", "#glyph-" + data[i][j][0])
				.attr("class", "glyph-" + data[i][j][0])
				.attr("transform", "matrix(" + fontScale * 16 + " 0 0 "  + fontScale * relHeight +  " " + x(start+i) + " " + ypos + ")");
			ypos -=  relHeight;
		}
	}
}


logoplot.buildChunked = function(sequences, targetElement, options = {}) {
	const { width = 1120, height = 120, chunkSize = 80 } = options;
	const counts = logoplot.computeCounts(sequences);
	const max = maxCount(counts);
	for (let i = 0; i < counts.length; i += chunkSize) {
		let start = i;
		let end = Math.min(i + chunkSize, counts.length);
		let countsChunk = counts.slice(start, end);
		let $el = document.createElement('div');
		$el.style.marginTop = '10px';
		targetElement.appendChild($el);
		logoplot.build($el, countsChunk, { 
			width: width, 
			height: height,
			start: start,
			end: start + chunkSize - 1,
			max: max,
		});
	}
};

export { logoplot };